import {ref} from 'vue';
import {stockList as stockListApi} from "@/utils/api/procurement/stock";
import message from "@utils/tool/message";

export default function () {
    const stockList = ref([])
    const getStockListData = async (data = {
        all: false,
        status: true
    }, callBack) => {
        const res = await stockListApi(data)
        if (res.code === 0) {
            const list = res.data?.list || []
            if (typeof callBack === 'function') {
                callBack(list)
            }
            if (list.length === 1) {
                stockList.value = list
            } else if (list.length && list.length > 1) {
                stockList.value.push({stockId: '', stockName: '全部仓库'})
                stockList.value.push(...list)
            }
        } else {
            message.error(res.msg)
        }
        return res
    }


    return {
        stockList,
        getStockListData
    }
}