<template>
  <el-dialog
      title="货架详情"
      :model-value="modelValue"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @open="openDia"
      destroy-on-close
      align-center
      :before-close="closeDia"
  >
    <div class="shelfDetail">
      <el-row :gutter="20">
        <el-col :span="4">
          <div class="ep-bg-purple">仓库名称：</div>
        </el-col>
        <el-col :span="8">
          <div class=" ep-bg-purple">{{ shelfDetail.stockName }}</div>
        </el-col>
        <el-col :span="6" :offset="6">
          <div class=" ep-bg-purple">
            <up-date-shelf-modal :info="shelfDetail" @success="data=>Object.assign(shelfDetail,data)"
                                 class="mr_10"></up-date-shelf-modal>
            <q-rcode-modal :info="shelfDetail"></q-rcode-modal>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4">
          <div class=" ep-bg-purple">详细地址：</div>
        </el-col>
        <el-col :span="18">
          <div class=" ep-bg-purple">{{ shelfDetail.stockAddress }}</div>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4">
          <div class=" ep-bg-purple">货架名称：</div>
        </el-col>
        <el-col :span="18">
          <div class=" ep-bg-purple">{{ shelfDetail.shelfName }}</div>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4">
          <div class=" ep-bg-purple">备注：</div>
        </el-col>
        <el-col :span="18">
          <div class="ep-bg-purple">{{ shelfDetail.remark }}</div>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4">
          <div class=" ep-bg-purple">状态：</div>
        </el-col>
        <el-col :span="18">
          <div class=" ep-bg-purple">{{ shelfDetail.status ? '开启' : '禁用' }}</div>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="4">
          <div class=" ep-bg-purple">货架列表：</div>
        </el-col>
      </el-row>
      <shelf-list :shelf-info="shelfDetail">
        <template #title="{row}">货架{{ row }}</template>
        <template #default="{index,column,row}">
          <div>
            <div>
              {{ shelfDetail.childList[index]?.childShelfName }}
            </div>
            <div>产品种类： {{ shelfDetail.childList[index]?.productCategoryCount }}</div>
            <div>产品数量： {{ shelfDetail.childList[index]?.productCount }}</div>
          </div>
        </template>
      </shelf-list>
    </div>
  </el-dialog>
</template>

<script setup>
import {toRefs} from "vue";
import QRcodeModal from "@views/personal/shelfManagement/component/shelfDetailModal/QRcodeModal/index.vue";
import upDateShelfModal from "@views/personal/shelfManagement/component/upDateShelfModal/index.vue";
import shelfList from "@views/personal/shelfManagement/component/shelfList/index.vue";

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false
  },
  shelfDetail: {
    type: Object,
    default: () => ({})
  }
})
const emit = defineEmits(['update:modelValue', 'closeModal'])
const {modelValue, shelfDetail} = toRefs(props)
const openDia = () => {
}
const closeDia = () => {
  emit('closeModal')
  emit('update:modelValue', false)
}

</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "shelfDetailModal",
});
</script>

<style lang="scss" scoped>
.list, .shelfDetail {
  margin-top: -20px;
}

.el-row {
  margin-top: 20px;
}

.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
  background: #2DACCC;
  color: #fff;
  text-align: center;
}

</style>
