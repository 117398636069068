<template>
  <div class="queryBoxOne" >
      <div class="btnBox">
        <slot></slot>
      </div>
    <form class="queryItem" @submit.prevent="handleSubmit">
        <template v-for="(item,index) in props.list" :key="index">
          <el-input
            v-if="item.type === 'input'"
            v-model="model[item.key]"
            :style="{width:item.width ?item.width +'px' :'200px'}"
            :placeholder="item.placeholder"
            clearable
          />
          <el-cascader
          v-else-if="item.type === 'cascader'"
          v-model="model[item.key]"
          :options="item.options"
          :props="queryCandiyConfig"
          :style="{width:item.width ?item.width +'px' :'200px'}"
          :placeholder="item.placeholder"
          clearable
          filterable
        >
        </el-cascader>
          <el-select
              v-else-if="item.type === 'select'"
              :style="{width:item.width ?item.width +'px' :'200px'}"
              :placeholder="item.placeholder"
              clearable
              v-model="model[item.key]">
            <el-option v-for="(i,index) in item.options" :key="index" :label="i.label" :value="i.value"/>
          </el-select>
        <el-date-picker
            v-else-if="item.type === 'date'"
            v-model="model[item.key]"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :style="{width:item.width ?item.width +'px' :'200px'}"
            :placeholder="item.placeholder"
            clearable
        />
          <select-by-abc-modal v-else-if="item.type === 'selectModal'"
                               v-model="model[item.key]"
                               :type="item.seletType"
                               :style="{width:item.width ?item.width +'px' :'200px'}"
          />
        </template>
        <div class="queryBtn">
          <el-button type="primary" class="square" native-type="submit"
            ><el-icon><Search /></el-icon
          ></el-button>
          <slot name="footer"></slot>
        </div>
      </form>
    </div>
</template>

<script setup>
import {toRefs} from "vue";
import SelectByAbcModal from "@/components/form/selectByModalNew/index.vue";
import {useVModel} from '@/utils/tool/useVModel'
import {queryCandiyConfig} from '@/utils/formConfig.js'

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => {}
  },
  list:{
    type:Array,
    default:()=>[]
  }
})
const { list } = toRefs(props)
const emit = defineEmits(['update:modelValue','onOk'])
const model = useVModel(props, 'modelValue', emit)
const handleSubmit = () => {
  emit('onOk', props.modelValue)
}
</script>
<script>
import { defineComponent } from 'vue';
export default defineComponent({
    name:'QueryBox'
})
</script>

<style lang="scss" scoped>

</style>