<template>
  <div class="zh-main-app shelf-management">
    <div class="zh-main-content ">
      <query-box :list="queryList" v-model="formData" @on-ok="search">
        <add-shelf-btn @ok="search"></add-shelf-btn>
      </query-box>
      <div class="tabList">
        <zh-table :data="shelfList" @cell-click="checkGoods">
          <el-table-column
              width="100"
              label="序号"
              align="center"
          >
            <template #default="scope">
              <div>{{ scope.$index + 1 }}</div>
            </template>
          </el-table-column>
          <template v-for="colum in shelfTableColum" :key="colum.fieldKey">
            <el-table-column
                :label="colum.title"
                :class-name="colum.className"
                align="center"
                show-overflow-tooltip
                v-if="colum.fieldKey === 'status' "
            >
              <template #default="{row}">
                <el-switch
                    v-model="row.status"
                    width="60"
                    size="large"
                    inline-prompt
                    active-text="开启"
                    inactive-text="禁用"
                    :before-change="changeStatus.bind(this,row)"
                />
              </template>
            </el-table-column>
            <el-table-column
                v-else
                :prop="colum.fieldKey"
                :label="colum.title"
                :class-name="colum.className"
                align="center"
                show-overflow-tooltip
            >
              <template #default="scope">
                <div>{{ scope.row[colum.fieldKey] || '--' }}</div>
              </template>
            </el-table-column>
          </template>
        </zh-table>
      </div>
      <div class="mainPage">
        <el-pagination
            v-model:current-page="pageInfo.pageNumber"
            layout="total, prev, pager, next , jumper"
            :total="pageInfo.total"
            v-model:page-size="pageInfo.pageSize"
            @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <shelf-detail-modal v-model="showModal" @closeModal="getShelfList" :shelf-detail="shelfDetail"></shelf-detail-modal>
  </div>
</template>

<script setup>
import {computed, onMounted, provide, ref} from "vue";
import queryBox from "@components/queryBox/index.vue";
import useStock from "@utils/hooks/useStock";
import useShelfList from "@utils/hooks/shelf/useShelfList";
import useShelfControl from "@utils/hooks/shelf/useShelfControl";
import {getDomHeight} from "@utils/tool/getDomHeight";
import {shelfTableColum} from './config'
import {ElMessageBox} from "element-plus";
import addShelfBtn from "@views/personal/shelfManagement/component/addShelfBtn/index.vue";
import useModal from "@utils/hooks/useModal";
import shelfDetailModal from "@views/personal/shelfManagement/component/shelfDetailModal/index.vue";
import useShelfDetail from "@utils/hooks/shelf/useShelfDetail";
import {useRouter} from "vue-router";
import filter from "@/utils/filter";

const router = useRouter()
const {shelfDetail} = useShelfDetail()
const {showModal} = useModal()
const {domHeight} = getDomHeight()
const {pageInfo, shelfList, getShelfList} = useShelfList()
const {handleChangeShelfStatus} = useShelfControl()
const {stockList, getStockListData} = useStock()
provide('stockList', stockList)
const queryList = computed(() => {
  return [
    {
      type: "select",
      key: "stockId",
      placeholder: "请选择仓库",
      width: 200,
      options: stockList.value.map(item => ({
        label: item.stockName,
        value: item.stockId
      }))
    },
  ]
})

const formData = ref({
  stockId: '',
})

const search = (query) => {
  getList()
}
const getList = () => {
  getShelfList(formData.value, (item) => {
    item.createTime = filter.timeFormat(item.createTime)
  })
}

const handleCurrentChange = (val) => {
  pageInfo.pageNumber = val
  getList()
}

//开启关闭货架
const changeStatus = async (row) => {
  const text = row.status ? "已上架产品不受影响，后续不能再上架商品，确认禁用该货架？" : "确认启用该货架？";
  await ElMessageBox.confirm(text, "提示", {
    confirmButtonText: "确认",
    cancelButtonText: "取消",
    customClass: "zh-message-box",
  })
  return new Promise(async (resolve, reject) => {
    if (await handleChangeShelfStatus(row)) {
      resolve(true)
    } else {
      reject(false)
    }
  })
}
onMounted(async () => {
  await getStockListData({all: false, status: true})
  getList()
})

const checkGoods = (row, column) => {
  if (column.property) {
    router.push({
      path: "/personal/shelfManagement/detail",
      query: {
        id: row.shelfId,
        stockId: row.stockId
      }
    })
  }
};
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "shelfManagement",
});
</script>

<style lang="scss" scoped>
.zh-main-app {
  .zh-main-content {
    position: relative;

    .mainPage {
      left: 32px;
      width: calc(100% - 64px);
    }

    .tabList {
      height: calc(100% - v-bind(domHeight) - 77px);
      margin-top: 20px;
    }
  }
}
</style>
