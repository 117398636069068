import request from '../../http'
import {managerUrl} from '../../api'
//产品品牌信息

//产品品牌列表
export function brandList(data){
  return request({
    url:managerUrl + "/carton/product/brand/info",
    method:'post',
    data
  })
}

//产品品牌新增
export function brandAdd(data){
  return request({
    url:managerUrl + "/carton/product/brand/add",
    method:'post',
    data
  })
}

//产品品牌列表
export function brandDel(data){
  return request({
    url:managerUrl + "/carton/product/brand/del",
    method:'post',
    data
  })
}

//产品单位列表
export function unitList(data){
  return request({
    url:managerUrl + "/carton/product/unit/info",
    method:'post',
    data
  })
}

//产品单位新增
export function unitAdd(data){
  return request({
    url:managerUrl + "/carton/product/unit/add",
    method:'post',
    data
  })
}

//产品单位删除
export function unitDel(data){
  return request({
    url:managerUrl + "/carton/product/unit/del",
    method:'post',
    data
  })
}


