<template>
  <el-button v-bind="$attrs" type="primary" @click="addShelf">添加货架</el-button>
</template>

<script setup>
import {useRouter} from "vue-router";

const router = useRouter()
const emit = defineEmits(['ok'])
const addShelf = () => {
  router.push('/personal/shelfManagement/add')
}
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "addShelfBtn",
});
</script>

<style lang="scss" scoped>

</style>
