export const shelfTableColum = [
  {
    title: "货架名称",
    fieldKey: "shelfName",
    width: "200",
    isShow: true,
    className: "table_text2",
  },
  {
    title: "仓库名称",
    fieldKey: "stockName",
    width: "200",
    isShow: true,
    className: "table_text",
  },
  {
    title: "创建人",
    fieldKey: "createUserName",
    width: "200",
    isShow: true,
    className: "table_text",
  },
  {
    title: "创建时间",
    fieldKey: "createTime",
    width: "200",
    isShow: true,
    className: "table_text",
  },
  {
    title: "备注",
    fieldKey: "remark",
    width: "",
    isShow: true,
    className: "table_text",
  },
  {
    title: "状态",
    fieldKey: "status",
    width: "",
    isShow: true,
    className: "table_text",
  },
];

export const childShelfProductTableColum = [
  {
    title: "产品名称",
    fieldKey: "productName",
    className: "table_text2",
  },
  {
    title: "简称",
    fieldKey: "subName",
    className: "table_text",
  },
  {
    title: "产品品牌",

    fieldKey: "brandName",
    className: "table_text",
  },
  {
    title: "规格/型号",
    fieldKey: "modelName",
    className: "table_text",
  },
  {
    title: "产品单位",
    fieldKey: "unitName",
    className: "table_text2",
  },
  {
    title: "数量",
    fieldKey: "childShelfProductCount",
    className: "table_text",
  },
];
