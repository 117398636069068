export const rules = {
    stockId: [{
        required: true,
        message: "请选择仓库",
        trigger: "change"
    }],
    shelfName: [{
        required: true,
        message: "请输入货架名称",
        trigger: "blur"
    },
        {
            max: 10,
            message: "长度在 10 个字符以内",
            trigger: "blur"
        }
    ],
    column: [
        {
            required: true,
            message: "请输入列数",
            trigger: "blur"
        },
        {
            validator: max(30),
            trigger: "blur"
        },
        {
            validator: min(1),
            trigger: "blur"
        }
    ],
    row: [
        {
            required: true,
            message: "请输入行数",
            trigger: "blur"
        },
        {
            validator: max(30),
            trigger: "blur"
        },
        {
            validator: min(1),
            trigger: "blur"
        }
    ],
}

function max(num = 10) {
    return function (rule, value, callback) {
        if (+value > num) {
            callback(new Error(`最大值为${num}`))
        }
        callback()
    }
}

function min(num = 0) {
    return function (rule, value, callback) {
        if (+value < num) {
            callback(new Error(`最小值为${num}`))
        }
        callback()
    }
}

export const labelRemarkMaxNum = 200;

export const otherOrderTypeMaxNum = 6;