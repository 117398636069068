import {ref} from "vue";
import {usePagination} from "@utils/tool/page";
import message from "@utils/tool/message";
import {getShelfList as getShelfListApi} from '@/utils/api/shelf'

export default function () {
    const {pageInfo, reset} = usePagination()
    //货架列表
    const shelfList = ref([])

    const getShelfList = async (query, callBack) => {
        //todo
        const {data, code, msg} = await getShelfListApi({
            ...query,
            pageSize: pageInfo.pageSize,
            pageNumber: pageInfo.pageNumber
        })
        if (code !== 0) {
            message.error(msg)
            return
        }
        pageInfo.total = data?.total || 0
        const list = data?.list || []
        if (callBack) {
            list.forEach((item, index) => {
                callBack(item, index)
            })
        }
        shelfList.value = list
    }

    return {
        shelfList,
        getShelfList,
        pageInfo,
        reset
    }
}