<template>
  <el-button type="primary" v-bind="$attrs" @click="handleEdit">编辑</el-button>
  <el-dialog
      title="编辑货架"
      v-model="showModal"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @open="openDia"
      destroy-on-close
      align-center
  >
  <el-form :rules="formRule"
             ref="formRef"
             :model="shelfInfo"
             label-width="150px"
             label-position="right">
      <el-form-item label="货架名称" prop="shelfName">
        <el-input v-model.lazy="shelfInfo.shelfName" v-elInput clearable maxlength="10"/>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input
            v-model.lazy="shelfInfo.remark"
            type="textarea"
            placeholder="输入信息"
            class="textareaInput"
            resize="none"
            v-elInput
            maxlength="50"
        />
      </el-form-item>
    </el-form>
    <shelf-list :shelf-info="shelfInfo">
      <template #title="{row}">货架{{ row }}</template>
      <template #default="{index,column,row}">
        <div>
          <div>
            {{ shelfInfo.childList[index]?.childShelfName }}
          </div>
          <div @click.self="changeStatus(index)">{{ shelfInfo.childList[index]?.status ? '开启' : '禁用' }}</div>
        </div>
      </template>
    </shelf-list>
    <template #footer>
      <el-button @click="closeDia">取 消</el-button>
      <el-button type="primary" @click="submit">保存</el-button>
    </template>
  </el-dialog>
</template>

<script setup>
import useModal from "@/utils/hooks/useModal";
import {reactive, ref, toRefs} from "vue";
import useShelfControl from "@utils/hooks/shelf/useShelfControl";
import {rules} from '@/utils/config/ruleConfig'
import shelfList from "@views/personal/shelfManagement/component/shelfList/index.vue";

const props = defineProps({
    info:{
        type:Object,
        default:()=>({})
    }
})
const emit = defineEmits(['success'])
const { info } = toRefs(props)
const {shelfInfo, handleEditShelf, setShelfInfo} = useShelfControl()
const formRef = ref(null)
const formRule = reactive({
  stockId: rules.stockId,
  shelfName: rules.shelfName,
  remark: rules.remark,
  column: rules.column,
  row: rules.row
})
const {showModal, handleOpenModal,handleCloseModal} = useModal()

const changeStatus = (row) => {
  shelfInfo.childList[row].status = !shelfInfo.childList[row].status
}
const openDia = () => {
  setShelfInfo(info.value)
}

const handleEdit = async () => {
  handleOpenModal()
}

const closeDia = () => {
  handleCloseModal()
}

const submit = async () => {
  const form = formRef.value
  if (!form) return
  await form.validate(async (valid, fields) => {
    if (valid) {
      if (await handleEditShelf()) {
        emit('success', shelfInfo)
        handleCloseModal()
      }
    } else {
    }
  })
}
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "upDateShelfModal",
});
</script>

<style lang="scss" scoped>
.el-row {
  margin-top: 20px;
}

.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
  background: #2DACCC;
  color: #fff;
  text-align: center;
}
</style>
