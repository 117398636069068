import request from '../http'
import {managerUrl} from '../api'
//客户信息

//客户数量统计
export function punterCensus(data){
  return request({
    url:managerUrl + "/carton/product/punter/census",
    method:'post',
    data
  })
}

//客户列表（正常）
export function punterList(data){
  return request({
    url:managerUrl + "/carton/product/punter/list",
    method:'post',
    data
  })
}

//客户列表（作废）
export function punterLIstDiscard(data){
  return request({
    url:managerUrl + "/carton/product/punter/list/discard",
    method:'post',
    data
  })
}

//客户作废
export function punterDiscard(data){
  return request({
    url:managerUrl + "/carton/product/punter/discard",
    method:'post',
    data
  })
}

//客户恢复
export function punterResume(data){
  return request({
    url:managerUrl + "/carton/product/punter/resume",
    method:'post',
    data
  })
}

//客户新增
export function punterAdd(data){
  return request({
    url:managerUrl + "/carton/product/punter/add",
    method:'post',
    data
  })
}

//客户编辑
export function punterEdit(data){
  return request({
    url:managerUrl + "/carton/product/punter/edit",
    method:'post',
    data
  })
}

//客户列表查询
export function punterSearch(data){
  return request({
    url:managerUrl + "/carton/product/punter/query",
    method:'post',
    data
  })
}