import {computed, reactive, ref, watchEffect} from "vue";
import message from "@utils/tool/message";
import {addShelf, updateShelf, updateShelfStatus} from '@/utils/api/shelf'
import {cloneDeep} from "lodash";

export default function () {
    const showList = ref(false)
    //货架详情
    const shelfInfo = reactive({
        shelfId: '',
        shelfName: '',
        remark: '',
        column: '',
        row: '',
        stockId: '',
        childList: [],//子货架列表  status 子货架状态  childShelfId 子货架id childShelfName 子货架名称
    })

    //添加货架
    const handleAddShelf = async () => {
        const {msg, code} = await addShelf(shelfInfo)
        if (code === 0) {
            message.success('添加成功')
            return true
        } else {
            message.error(msg)
            return false
        }
    }

    const setChildList = () => {
        if (!shelfInfo.shelfId) {
            initShelfChildList()
        } else {
            setShelfInfoChildList()
        }
    }
    const setShelfInfoChildList = () => {
        initChildList(shelfInfo.childList)
    }
    const initChildList = (arr) => {
        let newList = arr.sort((a, b) => a.childShelfId - b.childShelfId)
        for (let i = 1; i <= shelfInfo.row; i++) {
            for (let j = 1; j <= shelfInfo.column; j++) {
                const index = (i - 1) * shelfInfo.column + j - 1
                const column = i < 10 ? `0${i}` : i
                const row = j < 10 ? `0${j}` : j
                newList[index] = {
                    ...newList[index],
                    column: j,
                    row: i,
                    childShelfName: `${shelfInfo.shelfName}-${column}-${row}`
                }
            }
        }
        shelfInfo.childList = newList
    }
    //初始化货架
    const initShelfChildList = () => {
        const length = +shelfInfo.column * +shelfInfo.row
        const arr1 = new Array(length).fill({status: 1, childShelfId: 0})
        initChildList(arr1)
    }
    //设置货架详情
    const setShelfInfo = (data) => {
        const info = cloneDeep(data)
        shelfInfo.shelfId = info.shelfId || 0;
        shelfInfo.shelfName = info.shelfName || '';
        shelfInfo.remark = info.remark || '';
        shelfInfo.childList = info.childList?.sort((a, b) => a.childShelfId - b.childShelfId) || [];
        shelfInfo.column = info.column || 1;
        shelfInfo.row = info.row || 1;
        shelfInfo.status = info.status || 0;
        shelfInfo.stockId = info.stockId || '';
        shelfInfo.stockName = info.stockName || '';
        shelfInfo.stockAddress = info.stockAddress || '';
    }

    //操作货架状态
    const handleChangeShelfStatus = async (row) => {
        // type = 1 启用 0 禁用
        const {msg, code} = await updateShelfStatus({status: !row.status, shelfId: row.shelfId})
        if (code === 0) {
            message.success('操作成功')
            return true
        } else {
            message.error(msg)
            return false
        }
    }
    //是否可以查看效果
    const isCanSee = computed(() => {
        return shelfInfo.column && shelfInfo.row && shelfInfo.column <= 30 && shelfInfo.row <= 30 && shelfInfo.row >= 1 && shelfInfo.column >= 1 && shelfInfo.shelfName
    })

    //编辑货架
    const handleEditShelf = async () => {
        //todo
        const {msg, code} = await updateShelf(shelfInfo)
        if (code === 0) {
            message.success('操作成功')
            return true
        } else {
            message.error(msg)
            return false
        }
    }

    watchEffect(() => {
        if (shelfInfo.column && shelfInfo.row && shelfInfo.column <= 100 && shelfInfo.row <= 100 && shelfInfo.shelfName) {
            showList.value = false
            setChildList()
        }
    })

    const look = () => {
        showList.value = !showList.value
    }

    return {
        look,
        showList,
        handleAddShelf,
        handleChangeShelfStatus,
        handleEditShelf,
        setShelfInfo,
        shelfInfo,
        setChildList,
        isCanSee
    }
}