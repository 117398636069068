import {reactive} from "vue";
import message from "@utils/tool/message";
import {getShelfDetail as getShelfDetailApi} from '@/utils/api/shelf'

export default function () {
    //货架详情
    const shelfDetail = reactive({
        shelfName: '',
        shelfId: 0,
        stockName: '',
        stockAddress: '',
        createUserName: '',
        createTime: '',
        status: 0,
        remark: '',
        column: 0,
        row: 0,
        childList: [], //子货架列表  status 子货架状态  childShelfId 子货架id childShelfName 子货架名称
    })

    //获取货架详情
    const getShelfDetail = async (info) => {
        const {data, code, msg} = await getShelfDetailApi(info)
        if (code !== 0) {
            message.error(msg)
            return
        }
        setShelfInfo(data)
    }

    //设置货架详情
    const setShelfInfo = (data) => {
        Object.assign(shelfDetail, data)
        shelfDetail.childList = data.childList?.sort((a, b) => a.childShelfId - b.childShelfId) || [];
    }

    return {
        shelfDetail,
        getShelfDetail,
        setShelfInfo
    }
}